/*###########################################################################
// Header
//#########################################################################*/

import { throttle } from '../utilities/_throttleDebounce'

const header = {
	config: {
		header: '.header',
		headerNav: '.header__nav',
		headerNavItem: '.header__nav__item',
		headerNavItemSubmenus: '.header__nav__item--submenu',
		headerSubmenu: '.header__submenu',
		headerMenuButton: '.header__menu-btn',
		headerSubmenuSecondaryMenuItem: '.header__submenu__secondary-menu-item',
		headerSubmenuTertiaryMenu: '.header__submenu__tertiary-menu'
	},

	elements: {
		header: null,
		headerNav: null,
		headerNavItem: null,
		headerNavItemSubmenus: null,
		headerSubmenu: null,
		headerMenuButton: null,
		headerSubmenuSecondaryMenuItem: null,
		headerSubmenuTertiaryMenu: null
	},

	init() {
		const self = this

		self.elements.header = document.querySelector(self.config.header)
		self.elements.headerNav = document.querySelector(self.config.headerNav)
		self.elements.headerNavItem = document.querySelector(self.config.headerNavItem)
		self.elements.headerNavItemSubmenus = document.querySelectorAll(self.config.headerNavItemSubmenus)
		self.elements.headerSubmenu = document.querySelectorAll(self.config.headerSubmenu)
		self.elements.headerMenuButton = document.querySelector(self.config.headerMenuButton)
		self.elements.headerMenuButton = document.querySelector(self.config.headerMenuButton)
		self.elements.headerSubmenuSecondaryMenuItem = document.querySelectorAll(self.config.headerSubmenuSecondaryMenuItem)
		self.elements.headerSubmenuTertiaryMenu = document.querySelectorAll(self.config.headerSubmenuTertiaryMenu)


		// Looping through nav items that have sub menus and
		for (let i = 0; i < self.elements.headerNavItemSubmenus.length; i++) {
			const headerNavItemSubmenus = self.elements.headerNavItemSubmenus[i]
			const headerSubmenuSecondaryMenuItem = self.elements.headerSubmenuSecondaryMenuItem[i]
			const headerSubmenuTertiaryMenu = self.elements.headerSubmenuTertiaryMenu[i]
			const headerSubmenu = self.elements.headerSubmenu[i]


			headerNavItemSubmenus.addEventListener('click', function(){
				console.log('clicked')
				if( headerSubmenu.classList.contains('header__submenu--show') ){
					headerSubmenu.classList.remove('header__submenu--show')
				} else if( !headerSubmenu.classList.contains('header__submenu--show') ){
					headerSubmenu.classList.add('header__submenu--show')
				}
			})


			headerSubmenuSecondaryMenuItem.addEventListener('click', function(){
				console.log('clicked')
				if( headerSubmenuTertiaryMenu.classList.contains('header__submenu__tertiary-menu--show') ){
					headerSubmenuTertiaryMenu.classList.remove('header__submenu__tertiary-menu--show')
				} else if( !headerSubmenuTertiaryMenu.classList.contains('header__submenu__tertiary-menu--show') ){
					headerSubmenuTertiaryMenu.classList.add('header__submenu__tertiary-menu--show')
				}
			})

		}



		// for (let i = 0; i < self.elements.headerSubmenuSecondaryMenuItem.length; i++) {
		// 	const headerSubmenuSecondaryMenuItem = self.elements.headerSubmenuSecondaryMenuItem[i]
		// 	const headerSubmenuTertiaryMenu = self.elements.headerSubmenuTertiaryMenu[i]

		// 	headerSubmenuSecondaryMenuItem.addEventListener('click', function(){

		// 		if( headerSubmenuTertiaryMenu.classList.contains('header__submenu--show') ){
		// 			headerSubmenuTertiaryMenu.classList.remove('header__submenu--show')
		// 		} else if( !headerSubmenuTertiaryMenu.classList.contains('header__submenu--show') ){
		// 			headerSubmenuTertiaryMenu.classList.add('header__submenu--show')
		// 		}
		// 	})
		// }

		// If dom width is less than 1024 add header--open class to header on click
		if( window.screen.width >= 1024){
			const header = self.elements.header
			const headerMenuButton = self.elements.headerMenuButton
			const headerNav = self.elements.headerNav

			headerMenuButton.addEventListener('click', function(){
			if (!header.classList.contains('header--open')) {
				header.classList.add('header--open')

			}else if(header.classList.contains('header--open')) {
				header.classList.remove('header--open')
			}
			})
		}
	},
}

window.addEventListener('load', function () {
	header.init()
})

/*###########################################################################
// Pill scroll
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger, )

const pillScroll = {
  config: {
    pillScroll: '.pillScroll'
  },

  elements: {},

  init() {
    const self = this

    self.elements.pillScroll = document.querySelectorAll(self.config.pillScroll)

    if (self.elements.pillScroll !== null) {
      for (let i = 0; i < self.elements.pillScroll.length; i++) {
        const pillScroll = self.elements.pillScroll[i]

        self.handlePillScroll(pillScroll)
      }
    }
  },

  handlePillScroll(pillScroll) {
    const self = this

    const pillScrollPills = pillScroll.querySelectorAll('.pillScroll div[data-pill]')
    const pillScrollText = pillScroll.querySelector('.pillScroll__text')

    // Initial sets for images and text
    //
    gsap.set(pillScrollText, { y: window.innerHeight / 10, autoAlpha: 0 });

    // Enter animations
    //
    let tl = gsap.timeline({
      paused: true
    });

    let parallax = gsap.timeline({
      paused: true,
			scrollTrigger: {
				start: "top top",
				// end: "bottom top",
				toggleActions: "play pause resume reset",
				trigger: pillScroll,
				markers: false,
				pin: true,
				scrub: 0.75,
			},
    });

    for (let i = 0; i < pillScrollPills.length; i++) {
      const pillScrollPill = pillScrollPills[i]

      let x = 0
      let y = 0
			let paraDur = 2

      if (i === 0) {
        x = window.innerWidth < 768 ? window.innerWidth / 7.25 : window.innerWidth / 4
        y = window.innerWidth < 768 ? window.innerHeight / 12 : window.innerHeight / 10
				paraDur = 1.8
      } else if (i === 1) {
        x = window.innerWidth < 768 ? window.innerWidth / 10.25 : window.innerWidth / 10
        y = window.innerWidth < 768 ? window.innerHeight / 4 : window.innerHeight / 4
				paraDur = 1.6
      } else if (i === 2) {
        x = window.innerWidth < 768 ? window.innerWidth / 4.5 : window.innerWidth / 3.25
        y = window.innerWidth < 768 ? window.innerHeight / 1.71 : window.innerHeight / 1.75
				paraDur = 1.4
      } else if (i === 3) {
        x = window.innerWidth < 768 ? window.innerWidth / 6.25 : window.innerWidth / 1.25
        y = window.innerWidth < 768 ? window.innerHeight / 1.1 : window.innerHeight / 1.3
				paraDur = 2
      } else if (i === 4) {
        // Hidden md up
        x = window.innerWidth / 6
        y = window.innerHeight / 1.8
				paraDur = 2.5
      } else if (i === 5) {
        x = window.innerWidth < 768 ? window.innerWidth / 1.75 : window.innerWidth / 1.3
        y = window.innerWidth < 768 ? window.innerHeight / 2.25 : window.innerHeight / 1.7
				paraDur = 2
      }

      gsap.set(pillScrollPill, {
        x: x,
        y: window.innerHeight,
        autoAlpha: 1,
        ease: "power2.out"
      })

      if (i === pillScrollPills.length-1) {
        tl.to(pillScrollText, {
          duration: 1.5,
          autoAlpha: 1,
          ease: "power2.out",
        }, "-=1.5")
      }

			parallax.to(pillScrollPill, {
				duration: paraDur,
				y: -window.innerHeight
			}, ">-1.5")
    }

    const enter = (scrollTriggerInstance) => {
      scrollTriggerInstance.kill(false, true)
    }

    ScrollTrigger.create({
      trigger: pillScroll,
      pin: false,
      start: "top +=" + window.innerHeight/2 + " middle",
      markers: false,
      animation: tl,
      // onEnter: enter,
      // onLeave: self => self.kill(false, true)
    })
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    pillScroll.init()
  }, 500)
})
